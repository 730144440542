import React, { useCallback, useEffect, useMemo, useState } from "react";

import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import _ from "lodash";

import UserHeader from "./components/UserHeader";
import Empty from "./components/Empty";
import PasswordList from "./components/PasswordList";

import CreateSheet from "../../containers/CreateSheet";
import DeleteSheet from "../../containers/DeleteSheet";
import CheckoutSheet from "../../containers/CheckoutSheet";

import { db, auth } from "../../constants/firebase-config";
import { setGlassesMode } from "../../config/store/reducers/userReducer";
import SettingsSheet from "../../containers/SettingsSheet";
import HomeFooter from "../Home/components/Footer";

import Background from "../../assets/bg-glasses.jpg";

export default function User() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);

  const [isOnline, setIsOnline] = useState(true);
  const [loadingUpdatingData, setLoadingUpdatingData] = useState({
    loading: false,
    type: "saved",
  });
  const [showsMenu, setShowsMenu] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (userData && userData?.data?.passwords) {
      saveState();
    }

    if (!isOnline) {
      setLoadingUpdatingData({ loading: false, type: "no_connection" });
    }

    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [userData, isOnline]);

  async function saveState() {
    try {
      let uid = auth.currentUser.uid;

      if (!uid) {
        setLoadingUpdatingData({ loading: false, type: "error" });
        return;
      }

      setLoadingUpdatingData({ loading: true, type: "saved" });
      let userRef = doc(collection(db, "users"), uid);

      let userDataRef = await getDoc(userRef);
      let userMainData = userDataRef.data();

      if (_.isEqual(userData.data.passwords, userMainData?.passwords)) {
        setLoadingUpdatingData({ loading: false, type: "saved" });
        return;
      }

      let passwords =
        userData.data.passwords && userData.data?.passwords?.length > 0
          ? userData.data.passwords.map((passwordItem) => {
              let item = { ...passwordItem };
              if (item.revealedHash) {
                delete item.revealedHash;
              }

              return item;
            })
          : [];

      await setDoc(
        userRef,
        {
          passwords,
        },
        { merge: true }
      );

      setLoadingUpdatingData({ loading: false, type: "saved" });
    } catch (e) {
      if (
        e?.message == "Failed to get document because the client is offline."
      ) {
        setLoadingUpdatingData({ loading: false, type: "no_connection" });
        return;
      }
      setLoadingUpdatingData({ loading: false, type: "error" });
    }
  }

  const changeGlassesMode = useCallback(() => {
    let uid = auth.currentUser.uid;

    dispatch(setGlassesMode());

    let userRef = doc(collection(db, "users"), uid);
    setDoc(
      userRef,
      {
        glassesMode:
          userData && userData.data ? !userData.data.glassesMode : true,
      },
      { merge: true }
    );
  }, [userData, dispatch]);

  const onChangeMenu = useCallback((value) => {
    setShowsMenu(value);
  }, []);

  const passwords = useMemo(
    () =>
      searchText.length > 1
        ? userData?.data?.passwords.filter((item) =>
            String(item.name).toLowerCase().includes(searchText.toLowerCase())
          )
        : userData?.data?.passwords || [],
    [searchText, userData]
  );

  const isGlassesMode = useMemo(
    () =>
      userData.data &&
      userData?.data?.glassesMode &&
      userData?.data?.passwords.length > 0,
    [userData.data]
  );

  return (
    <div className="bg-paper" onClickCapture={() => onChangeMenu(false)}>
      <Helmet>
        <title>Cloudsky | Dashboard</title>
      </Helmet>
      <div
        className="w-full"
        style={{
          background: isGlassesMode ? `url(${Background})` : "",
          backgroundPosition: isGlassesMode ? "center" : "",
        }}
      >
        <div className="max-w-[1680px] m-auto w-full">
          <UserHeader
            user={{
              name: userData?.data?.name || "",
              photoURL: userData?.data?.photoURL || "",
              email: userData?.data?.email || "",
              subscription: userData?.subscription,
            }}
            value={searchText}
            onChangeText={(value) => setSearchText(value)}
            loadingData={loadingUpdatingData}
            glassesMode={userData?.data?.glassesMode}
            changeGlassesMode={changeGlassesMode}
            showsMenu={showsMenu}
            onChangeMenu={onChangeMenu}
          />
          {userData && userData.data && userData.data.passwords && (
            <>
              {userData?.data?.passwords?.length == 0 ? (
                <Empty />
              ) : (
                <PasswordList
                  data={passwords}
                  glassesMode={userData?.data?.glassesMode}
                  subscription={userData?.subscription}
                />
              )}
            </>
          )}
        </div>
        <HomeFooter />
      </div>
      <CreateSheet />
      <DeleteSheet />
      <CheckoutSheet />
      <SettingsSheet />
    </div>
  );
}
