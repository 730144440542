import React, { memo, useCallback, useMemo, useState } from "react";

import moment from "moment";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import { httpsCallable } from "firebase/functions";
import cn from "classnames";

import Button from "../../../components/Button";

import Logo from "../../../assets/logo.svg";
import { ReactComponent as SkyIcon } from "../../../assets/sky.svg";
import { ReactComponent as LoadingIcon } from "../../../assets/loader.svg";
import { ReactComponent as LogoName } from "../../../assets/logo-name.svg";

import {
  setCheckoutSequence,
  setSettingsSheet,
} from "../../../config/store/reducers/sheetsReducer";

import { functions } from "../../../constants/firebase-config";

function UserHeader({
  user,
  value = "",
  onChangeText = () => {},
  loadingData,
  glassesMode,
  changeGlassesMode,
  showsMenu,
  onChangeMenu,
}) {
  const dispatch = useDispatch();
  const [loadingCustomerPortal, setLoadingCustomerPortal] = useState(false);

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "app.header",
  });

  const handleCustomerPortal = useCallback(async () => {
    try {
      setLoadingCustomerPortal(true);
      const createCustomerPortalRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await createCustomerPortalRef({
        returnUrl: window.location.origin,
        locale: i18n.language || "auto",
      });

      window.location.assign(data.url);
    } catch (error) {
      alert(String(error));
    } finally {
      setLoadingCustomerPortal(false);
    }
  }, []);

  const formatedPaidPlan = useMemo(() => {
    if (user?.subscription) {
      return moment(user?.subscription?.created_at * 1000).format("MM/YYYY");
    }
    return "";
  }, [user]);

  const savedTypes = useMemo(
    () => ({
      saved: t("status.saved"),
      error: t("status.error"),
      no_connection: t("status.no_connection"),
    }),
    [t]
  );

  return (
    <>
      <header className="pt-4 mb-2 flex items-center justify-between px-10 max-[600px]:px-10 max-[500px]:px-4">
        <div className="flex items-center max-[400px]:hidden">
          <LogoName className="pointer-events-none w-[130px] cursor-default max-[600px]:w-[100px]" />
          <span className="bg-primary ml-4 cursor-default select-none rounded-full px-4 text-xs text-white max-[900px]:text-[10px]">
            BETA
          </span>
        </div>
        <div className="hidden items-center max-[400px]:flex">
          <motion.img
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="pointer-events-none w-[28px] max-[400px]:flex"
            src={Logo}
          />
          <span className="bg-primary ml-4 cursor-default select-none rounded-full px-4 text-xs text-white max-[900px]:text-[10px]">
            BETA
          </span>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          className="mx-12 flex w-[700px] flex-row items-center rounded-xl border-[1px] border-gray-200 px-4 py-2 max-[1350px]:w-[500px] max-[1000px]:hidden"
        >
          <i size="18px" className="bx bx-search text-[18px]"></i>
          <input
            value={value}
            onChange={(e) => onChangeText(e.target.value)}
            className={`bg-black-paper font-medium w-full transition-all ${
              !value ? "pl-[30%]" : "pl-0"
            } mx-4 text-sm placeholder:text-gray-400 focus:pl-0 max-[1350px]:pl-0 `}
            placeholder={t("search_placeholder")}
          />
        </motion.div>
        <div className="flex flex-row items-center">
          <AnimatePresence>
            {loadingData.loading ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0 }}
                className="mr-2 max-[800px]:mr-0"
              >
                <LoadingIcon
                  stroke="#AAB0B4"
                  className={`littleLoadingIcon  max-[800px]:w[10px]  w-[20px]`}
                />
              </motion.div>
            ) : (
              <>
                <motion.span
                  aria-required={true}
                  aria-label="Everthing was saved"
                  className="font-medium cursor-default text-sm text-gray-400 max-[800px]:text-sm max-[500px]:hidden"
                >
                  {savedTypes[loadingData.type]}
                </motion.span>
                <div className="hidden h-5 w-5 items-center justify-center max-[500px]:flex">
                  <i className="bx bx-check-shield text-[20px] text-[#03182630]"></i>
                </div>
              </>
            )}
          </AnimatePresence>
          <div className="relative">
            <motion.div
              onClick={() => onChangeMenu(!showsMenu)}
              whileTap={{ scale: 0.8 }}
              className="ml-8 flex cursor-pointer flex-row items-center rounded-xl py-2 transition-all"
            >
              <span className="text-base font-medium max-[600px]:text-sm">
                {user?.name}
              </span>
              <img
                className="pointer-events-none ml-4 h-8 w-8 rounded-full object-cover max-[600px]:h-8 max-[600px]:w-8 max-[600px]:rounded-lg"
                src={user?.photoURL}
              />
            </motion.div>
            <AnimatePresence>
              {showsMenu && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ y: 20, opacity: 1 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="absolute right-0 top-20 z-10 w-72 rounded-xl bg-white  shadow-lg max-[600px]:py-2"
                >
                  {!user?.subscription ? (
                    <Button
                      title={t("plan.title")}
                      description={t("plan.free_description")}
                      descriptionComplement={
                        <div className="mt-4 flex flex-row items-center">
                          <SkyIcon />
                          <strong className="ml-4 text-base font-medium text-black">
                            {t("plan.sky_plan_name")}
                          </strong>
                        </div>
                      }
                      onClick={() => dispatch(setCheckoutSequence({}))}
                      isFirst
                    />
                  ) : (
                    <Button
                      title={t("plan.title")}
                      descriptionComplement={
                        <>
                          <div className="mt-4 flex flex-row items-center">
                            <SkyIcon />
                            <strong className="ml-4 text-base font-medium text-black">
                              Sky
                            </strong>
                          </div>
                          {!user?.subscription?.lifetime && (
                            <p className="my-2">
                              {t("plan.active")} {formatedPaidPlan}
                            </p>
                          )}
                          {!loadingCustomerPortal ? (
                            <p
                              className={cn(
                                "text-primary text-base font-medium",
                                {
                                  "mt-4 text-white bg-gradient-to-r from-primary to-secundary w-[130px] py-1 px-4 rounded-full flex flex-row items-center":
                                    !!user?.subscription?.lifetime,
                                }
                              )}
                            >
                              {!user?.subscription?.lifetime
                                ? t("plan.manage")
                                : t("plan.lifetime")}
                              {user?.subscription?.lifetime && (
                                <motion.div
                                  initial={{ scale: 0.8 }}
                                  whileInView={{
                                    scale: 1,
                                  }}
                                  transition={{
                                    delay: 0.1,
                                    type: "spring",
                                  }}
                                >
                                  <FaStar className="ml-4" />
                                </motion.div>
                              )}
                            </p>
                          ) : (
                            <LoadingIcon
                              stroke="#758BFD"
                              className={`littleLoadingIcon  max-[800px]:w[10px]  w-[20px]`}
                            />
                          )}
                        </>
                      }
                      onClick={() =>
                        user?.subscription?.lifetime
                          ? null
                          : handleCustomerPortal()
                      }
                    />
                  )}
                  <Button
                    title={t("glasses_mode")}
                    onClick={() => changeGlassesMode()}
                    icon={
                      <i
                        className={`bx ${
                          glassesMode
                            ? "bx-radio-circle-marked"
                            : "bx-radio-circle"
                        } text-[25px] text-[${
                          glassesMode ? "#758BFD" : "#03182630"
                        }]`}
                      ></i>
                    }
                  />
                  <Button
                    title={t("settings.title")}
                    onClick={() => dispatch(setSettingsSheet(true))}
                    isLast
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </header>
    </>
  );
}

export default memo(UserHeader);
