import _ from "lodash";
import storage from "../config/store";

const store = storage().store;

export function analyseUserAccount(data) {
  const storagePersistedData = store.getState().user;

  if (
    data?.passwords === 0 ||
    !storagePersistedData.data ||
    !storagePersistedData.data.passwords
  ) {
    return data;
  }

  let getAllRemotePasswords = data.passwords || [];
  let getPersistedPasswords = storagePersistedData.data.passwords || [];
  let getPersistedDefaultStructurePasswords = getPersistedPasswords.map(
    (passwordItem) => {
      let item = { ...passwordItem };
      if (item.revealedHash) {
        delete item.revealedHash;
      }

      return item;
    }
  );

  if (
    !_.isEqual(getAllRemotePasswords, getPersistedDefaultStructurePasswords)
  ) {
    return data;
  }

  return storagePersistedData.data;
}
