import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import {
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import { auth } from "../../constants/firebase-config";
import Layout from "../../components/Layout";

import GoogleIcon from "../../assets/google.jpg";
import { ReactComponent as LoaderIcon } from "../../assets/loader.svg";

import { setUser } from "../../config/store/reducers/userReducer";

const provider = new GoogleAuthProvider();

provider.addScope("https://www.googleapis.com/auth/userinfo.email");

export default function Auth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("translation", { keyPrefix: "login_route" });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/");
        return;
      }
    });

    const lng = searchParams.get("lng");
    if (lng) {
      provider.setDefaultLanguage(lng);
      auth.languageCode = lng;
    }

    return () => unsubscribe();
  }, [searchParams]);

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      dispatch(
        setUser({
          uid: user.uid,
          email: result.user?.email || "",
          photoURL: user.photoURL || "",
          passwords: [],
        })
      );

      const isFromPricing = searchParams.get("from_pricing");

      if (isFromPricing) {
        navigate("/?from_pricing=true");
        return;
      }

      navigate("/");
    } catch (error) {
      signOut(auth);
      const errorMessage = error.message;
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const title = t("title", { returnObjects: true });
  const description = t("description", { returnObjects: true });
  const acceptTerms = t("terms", { returnObjects: true });

  return (
    <Layout>
      <Helmet>
        <title>Cloudsky | Login</title>
        <meta
          name="description"
          content="Login or create a new CloudSky account. Doing this with a Google account, simple and fast."
        />
        {/* Facebook tags */}
        {/* <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} /> */}
        {/* End Facebook tags */}
        {/* Twitter tags */}
        {/* <meta name="twitter:creator" content={name} />}
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} /> */}
      </Helmet>
      <main className="bg-white w-full flex justify-center items-center flex-col">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1, type: "spring" }}
          className="w-[480px] h-[400px] rounded-3xl flex justify-center items-center flex-col mt-20 max-[600px]:w-[400px] max-[500px]:w-full"
          style={{
            backgroundImage:
              "linear-gradient(45deg, #D4CEFF60, #FF62FF40, #ffffff)",
          }}
        >
          <strong className="text-xl text-center font-semibold max-[600px]:text-lg">
            {title[0]} <br /> {title[1]}
          </strong>

          {!loading ? (
            <motion.div
              whileTap={{ scale: 0.8 }}
              onClick={() => handleLoginGoogle()}
              className="cursor-pointer w-[80px] h-[80px] bg-white rounded-full flex justify-center items-center my-10 max-[600px]:w-[60px] max-[600px]:h-[60px]"
            >
              <img
                className="rounded-full w-[60px] max-[600px]:w-[40px]"
                src={GoogleIcon}
              />
            </motion.div>
          ) : (
            <LoaderIcon stroke="#ffffff" className="littleLoadingIcon  my-10" />
          )}
          <p className="text-center text-base max-[600px]:text-sm">
            {description[0]} <br /> {description[1]}
          </p>
        </motion.div>
        <p className="text-center my-8 text-gray text-sm max-[600px]:text-xs">
          {acceptTerms[0]} <br /> {acceptTerms[1]}
        </p>
      </main>
    </Layout>
  );
}
