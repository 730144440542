import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import MainLoader from "../components/MainLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../constants/firebase-config";
import { useDispatch } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { analyseUserAccount } from "../utils/analyseUserAccount";
import {
  setUser,
  updateUserSubscription,
} from "../config/store/reducers/userReducer";
import { logOutUser } from "../utils/user";
import {
  setCheckoutSequence,
  setSettingsSheet,
} from "../config/store/reducers/sheetsReducer";
import i18n from "../config/i18n";

const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  var subscriptionSnapshot = null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      if (!user) {
        navigate("/auth");
        setLoading(false);
        return;
      }

      getUserData(user);
    });

    return () => {
      unsubscribe && unsubscribe();
      subscriptionSnapshot && subscriptionSnapshot();
    };
  }, [subscriptionSnapshot]);

  const getUserData = useCallback(async (user) => {
    if (!user?.uid || !user?.email) {
      handleSignOut();
      return;
    }

    const userDataRef = await getDoc(doc(db, "users", user?.uid));
    let userMainData = userDataRef.data();

    if (!userMainData) {
      userMainData = {
        passwords: [],
        glassesMode: true,
        email: user?.email || "",
        photoURL: user?.photoURL || "",
        name: user?.displayName,
      };

      await setDoc(doc(db, "users", user?.uid), userMainData);
    }

    const userAnalysedByPersistedData = analyseUserAccount(userMainData);

    dispatch(
      setUser({
        ...userAnalysedByPersistedData,
        uid: user?.uid,
      })
    );

    navigate("/");
    handleUserSubscription(user);
  }, []);

  const handleUserSubscription = useCallback(async (user) => {
    const uid = user.uid;
    const subscriptionsRef = query(
      collection(db, `users/${uid}/subscriptions`),
      where("status", "==", "active")
    );

    const isFromPricing = searchParams.get("from_pricing");

    dispatch(updateUserSubscription(null));

    subscriptionSnapshot = onSnapshot(subscriptionsRef, (snap) => {
      if (snap.docs.length > 0) {
        const activedSubscription = snap.docs[0].data();
        const lifetime =
          Object.keys(activedSubscription).length == 2 ? true : false;

        dispatch(
          updateUserSubscription({
            created_at: activedSubscription.created.seconds,
            status: activedSubscription.status,
            lifetime,
          })
        );
      } else if (isFromPricing) {
        dispatch(setCheckoutSequence({}));
      }
      setLoading(false);
      window.history.replaceState(null, null, "/");
    });
  }, []);

  const handleSignOut = async () => {
    setLoading(true);
    dispatch(setUser(null));
    dispatch(updateUserSubscription(null));
    dispatch(setSettingsSheet(null));
    await logOutUser();
    setLoading(false);
    window.location.assign("https://cloudsky.app/");
  };

  return (
    <AuthContext.Provider value={{ handleSignOut }}>
      {!loading ? children : <MainLoader />}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
