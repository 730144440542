import { Route, Routes } from "react-router-dom";
import "./styles/global.css";

import Home from "./pages/Home";
import Auth from "./pages/Auth";
import AuthProvider from "./contexts/AuthContext";
import ToastProvider from "../src/contexts/ToastProvider";

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
        </Routes>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
