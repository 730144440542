import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "./reducers";

const persistConfig = {
  key: "cloudsky_root_2023",
  storage,
  blacklist: ["sheets"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  let store = configureStore({
    reducer: persistedReducer,
  });
  let persistor = persistStore(store);

  return { store, persistor };
};
