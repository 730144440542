import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AnimatePresence, motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteDoc, doc } from "firebase/firestore";
import cn from "classnames";

import SheetWrapper, { SheetBox } from "../components/SheetWrapper";
import Button from "../components/Button";

import { setSettingsSheet } from "../config/store/reducers/sheetsReducer";

import { FiLogOut, FiChevronDown, FiChevronUp } from "react-icons/fi";
import PrimaryButton from "../components/PrimaryButton";
import { auth, db } from "../constants/firebase-config";
import { useAuth } from "../contexts/AuthContext";

const QUESTION = "QUESTION";
const PROCESSING = "PROCESSING";

export default function SettingsSheet() {
  const dispatch = useDispatch();

  const { handleSignOut } = useAuth();
  const userData = useSelector((state) => state.user);
  const settingsSheet = useSelector((state) => state.sheets.settingsSheet);

  const [showsAdvancedOptions, setShowsAdvancedOptions] = useState(false);
  const [deleteSection, setDeleteSection] = useState(null);
  const [deleteTimeCount, setDeleteTimeCount] = useState(10);

  const [loading, setLoading] = useState(false);
  const deleteInterval = useRef(null);

  const { t } = useTranslation("translation", {
    keyPrefix: "app.settings_sequence",
  });

  useEffect(() => {
    if (settingsSheet && deleteInterval.current && deleteTimeCount === 0) {
      clearInterval(deleteInterval.current);
      confirmDeleteAccount();
    } else if (!settingsSheet && deleteInterval.current) {
      clearInterval(deleteInterval.current);
    }
  }, [deleteTimeCount, settingsSheet]);

  const handleDeleteAccount = useCallback(async () => {
    setDeleteTimeCount(10);
    try {
      setLoading(true);

      deleteInterval.current = setInterval(() => {
        setDeleteTimeCount((prevCount) => {
          return prevCount - 1;
        });
      }, 1000);
    } catch (error) {
      alert("Error to delete your account");
    } finally {
      setLoading(false);
    }
  }, []);

  async function confirmDeleteAccount() {
    const user = auth.currentUser;
    const uid = user.uid;

    await deleteDoc(doc(db, "users", uid));
    handleSignOut();
  }

  const hasSubscriptionsActived = useMemo(() => {
    return userData.subscription && userData.subscription.status == "active";
  }, [userData.subscription]);

  return (
    <SheetWrapper
      visible={!!settingsSheet}
      onClose={() => {
        setDeleteSection(null);
        dispatch(setSettingsSheet(null));
      }}
    >
      <SheetBox>
        {!deleteSection && (
          <section className="flex flex-col items-center justify-center py-4 max-[450px]:py-4">
            <div className="relative">
              <motion.img
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.2, bounce: true }}
                className={cn(
                  "m-2 p-2 bg-white rounded-full h-24 w-24 max-[600px]:w-20 max-[600px]:h-20",
                  {}
                )}
                src={userData?.data?.photoURL}
              />
              <div
                className={cn(
                  "absolute inset-0 h-28 w-28 rounded-full max-[600px]:w-24 max-[600px]:h-24",
                  {
                    "bg-gradient-to-r from-primary to-yellow -z-10":
                      !!hasSubscriptionsActived && !userData.lifetime,
                    "bg-gradient-to-r from-primary to-secundary -z-10":
                      !!hasSubscriptionsActived && !userData.lifetime,
                  }
                )}
              />
            </div>
            <strong className="mt-4 text-xl font-semibold max-[600px]:text-lg max-[600px]:mt-2">
              {userData?.data?.name}
            </strong>
            <span className="text-gray text-sm max-[600px]:text-xs">
              {userData?.data?.email}
            </span>
            <div className="my-4 h-[1px] bg-gray-200 max-[600px]:my-2" />
          </section>
        )}
        {deleteSection && (
          <section className="flex flex-col items-center justify-center py-4 max-[450px]:py-4">
            {deleteSection === QUESTION && (
              <>
                <strong className="mt-4 text-xl font-semibold">
                  {t("are_sure")}
                </strong>
                <span className="text-gray text-sm text-center mx-8 mt-4 max-[600px]:mt-2">
                  {t("delete_question")}
                </span>
                <div className="my-4 h-[1px] bg-gray-200 max-[600px]:my-2" />
              </>
            )}
            {deleteSection === PROCESSING && (
              <>
                <span className="text-dark text-base text-center mx-8 mt-4 max-[600px]:mt-2 leading-4">
                  {t("confirm_delete_message")}
                </span>
                <span className="text-dark text-base text-center mx-3 max-[600px]:mt-2 leading-6">
                  {t("confirm_delete_message_hint")}
                </span>
              </>
            )}
          </section>
        )}
        {deleteSection === PROCESSING && (
          <section className="flex items-center justify-center mb-6 w-full ">
            <span className="text-gray-500 font-bold text-7xl">
              {deleteTimeCount}
            </span>
          </section>
        )}
        {!deleteSection && (
          <section>
            <Button
              title={t("plan")}
              icon={
                <span
                  className={cn("text-gray", {
                    "text-primary": !!hasSubscriptionsActived,
                  })}
                >
                  {hasSubscriptionsActived ? t("sky_plan_name") : "Free"}
                </span>
              }
              onClick={() => dispatch(setSettingsSheet(true))}
            />
            <Button
              title={t("log_out")}
              titleStyle="text-red"
              icon={<FiLogOut />}
              onClick={() => handleSignOut()}
              isLast
            />
          </section>
        )}
        {deleteSection === QUESTION && (
          <section className="flex items-center justify-between mt-4 mb-6 px-8 max-[450px]:px-6">
            <PrimaryButton
              title={t("confirm")}
              buttonStyle="w-full mr-2 bg-red"
              loading={loading}
              loadingColor="#FFFFFF"
              onClick={() => {
                handleDeleteAccount();
                setDeleteSection("PROCESSING");
              }}
            />
            <PrimaryButton
              title={t("back")}
              buttonStyle="w-full"
              grayStyle
              onClick={() => dispatch(setSettingsSheet(null))}
            />
          </section>
        )}
        {deleteSection === PROCESSING && (
          <section className="flex items-center justify-between mt-4 mb-6 px-8 max-[450px]:px-6">
            <PrimaryButton
              title={t("confirm_cancel")}
              buttonStyle="w-full"
              grayStyle
              onClick={() => {
                setDeleteSection(null);
                dispatch(setSettingsSheet(null));
              }}
            />
          </section>
        )}
        {!deleteSection && (
          <section className="flex flex-col items-center justify-center py-4">
            <motion.button
              whileTap={{ scale: 0.97 }}
              className="flex items-center"
              onClick={() => setShowsAdvancedOptions(!showsAdvancedOptions)}
            >
              {!showsAdvancedOptions ? <FiChevronDown /> : <FiChevronUp />}
              <span className="text-sm font-medium ml-2 max-[600px]:text-xs">
                {t("advanced_options")}
              </span>
            </motion.button>
            <AnimatePresence>
              {showsAdvancedOptions && (
                <motion.div
                  initial={{ opacity: 0, marginTop: -10 }}
                  animate={{ opacity: 1, marginTop: 10 }}
                  exit={{ opacity: 0, marginTop: -10 }}
                  transition={{ duration: 0.1 }}
                >
                  <motion.button
                    onClick={() => {
                      setDeleteSection("QUESTION");
                      setShowsAdvancedOptions(false);
                    }}
                    className="cursor-pointer text-sm font-medium text-gray max-[600px]:text-xs"
                  >
                    {t("delete_account")}
                  </motion.button>
                </motion.div>
              )}
            </AnimatePresence>
          </section>
        )}
      </SheetBox>
    </SheetWrapper>
  );
}
