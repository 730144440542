import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import getStoreConfig from "./config/store";
import "./config/i18n";

const { store, persistor } = getStoreConfig();
const helmetContext = {};

const router = createBrowserRouter([{ path: "*", element: <App /> }]);

const root = ReactDOM.createRoot(
  document.getElementById("cloudskyapp-root-hr")
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider context={helmetContext}>
        <Suspense fallback={null}>
          <RouterProvider router={router} />
        </Suspense>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);
