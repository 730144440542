import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  subscription: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    addNewPassword: (state, action) => {
      state.data.passwords = [...state.data.passwords, action.payload];
    },
    saveRevealedPassword: (state, action) => {
      const { encryptedId, revealedHash } = action.payload;

      const hashPosition = state?.data?.passwords.findIndex(
        (item) => item.hash == encryptedId
      );
      let allPasswords = [...state.data.passwords];

      allPasswords[hashPosition] = {
        ...allPasswords[hashPosition],
        revealedHash,
      };

      state.data = {
        ...state.data,
        passwords: allPasswords,
      };
    },
    deletePassword: (state, action) => {
      let list = state.data.passwords;
      state.data = {
        ...state.data,
        passwords: list.filter((item) => item.id != action.payload),
      };
    },
    setGlassesMode: (state, action) => {
      state.data = {
        ...state.data,
        glassesMode: !state.data.glassesMode,
      };
    },
    updateUserSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
});

export const {
  setUser,
  addNewPassword,
  deletePassword,
  setGlassesMode,
  saveRevealedPassword,
  updateUserSubscription,
} = userSlice.actions;

export default userSlice.reducer;
