import React from "react";

import { motion } from "framer-motion";

export default function Button({
  onClick,
  title,
  titleStyle = "",
  description = "",
  descriptionComplement = null,
  icon = null,
  isLast,
}) {
  return (
    <motion.div
      className="pt-3 cursor-pointer hover:bg-gray-200 transition-colors duration-500"
      onClick={onClick}
    >
      <button
        className={`mt-2 px-8 max-[600px]:px-6 max-[600px]:text-sm flex font-medium flex-row items-center justify-between text-base w-full ${titleStyle}`}
      >
        {title}
        {icon}
      </button>
      {(description || descriptionComplement) && (
        <p className="text-sm px-8 mt-2 text-gray max-[600px]:text-xs max-[600px]:px-6">
          {description}
          {descriptionComplement}
        </p>
      )}
      {!isLast ? (
        <div className="h-[1px] bg-gray-200 mt-6" />
      ) : (
        <div className="pb-4 min-[600px]:mt-2" />
      )}
    </motion.div>
  );
}
