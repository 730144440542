import React from "react";

import { FiCheck } from "react-icons/fi";

export default function PricingDetail({ title, description }) {
  return (
    <li className="list-none flex flex-col">
      <div className="flex flex-row items-center">
        <FiCheck className="text-primary" />
        <span className="text-sm ml-2">{title}</span>
      </div>
      <p className="mb-4 text-xs max-w-[350px] text-gray-400 ml-6 font-medium">
        {description}
      </p>
    </li>
  );
}
