import React from "react";

import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <div className="flex flex-col justify-between m-auto max-w-[1680px] min-h-[100vh] w-full">
      <Header />
      {children}
      <Footer />
    </div>
  );
}
