import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createSequence: null,
  deleteSequence: null,
  checkoutSequence: null,
  settingsSheet: null,
};

export const sheetsSlice = createSlice({
  name: "sheets",
  initialState,
  reducers: {
    setCreateSequence: (state, action) => {
      if (action.payload == null) {
        state.createSequence = null;
        return;
      } else if (!state.createSequence) {
        state.createSequence = action.payload;
      }

      state.createSequence = {
        ...state.createSequence,
        ...action.payload,
      };
    },
    setDeleteSequence: (state, action) => {
      if (action.payload == null) {
        state.deleteSequence = null;
        return;
      } else if (!state.deleteSequence) {
        state.deleteSequence = action.payload;
      }

      state.deleteSequence = {
        ...state.deleteSequence,
        ...action.payload,
      };
    },
    setCheckoutSequence: (state, action) => {
      if (action.payload == null) {
        state.checkoutSequence = null;
        return;
      } else if (!state.deleteSequence) {
        state.checkoutSequence = action.payload;
      }

      state.checkoutSequence = {
        ...state.checkoutSequence,
        ...action.payload,
      };
    },
    setSettingsSheet: (state, action) => {
      const value = action.payload;
      state.settingsSheet = value;
    },
  },
});

export const {
  setCreateSequence,
  setDeleteSequence,
  setCheckoutSequence,
  setSettingsSheet,
} = sheetsSlice.actions;

export default sheetsSlice.reducer;
