import React from "react";

import { motion } from "framer-motion";
import { ReactComponent as LoadingIcon } from "../assets/loader.svg";

export default function PricingButton({
  title,
  contentStyle = "",
  loading = false,
  onClick = () => {},
}) {
  return (
    <motion.button
      className={contentStyle}
      whileTap={{ scale: 0.9 }}
      onClick={() => {}}
    >
      <button
        onClick={onClick}
        className="h-[50px] bg-primary flex justify-center items-center w-[300px] rounded-xl max-[600px]: max-[450px]:w-[200px] max-[450px]:h-[40px]"
        style={{
          backgroundImage:
            "linear-gradient(45deg, #758BFD 35%, #FFE577, #E58EFA)",
        }}
      >
        <div
          className="h-[45px] w-[98.33%] bg-white flex flex-row justify-center items-center max-[450px]:h-[36px] max-[450px]:w-[97.33%]"
          style={{
            borderRadius: 10,
          }}
        >
          {!loading ? (
            <strong className="font-medium text-base text-black max-[450px]:text-sm">
              {title}
            </strong>
          ) : (
            <LoadingIcon
              stroke="#AAB0B4"
              className={`littleLoadingIcon  w-[40px]  max-[800px]:w[10px]`}
            />
          )}
        </div>
      </button>
    </motion.button>
  );
}
