import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export function getTimestamp() {
  const nowDate = moment().valueOf();
  return nowDate;
}

function getDiffs(time) {
  // post

  const nowDate = getTimestamp();
  const postConfig = moment.utc(time).toDate();
  const nowConfig = moment.utc(nowDate).toDate();

  const range = moment.range(postConfig, nowConfig);
  const diffDays = range.diff("days");
  const diffMonths = range.diff("months");
  const diffYear = range.diff("years");
  const diffHours = range.diff("hours");
  const diffMinutes = range.diff("minutes");
  const diffSeconds = range.diff("seconds");

  return {
    diffYear,
    diffMonths,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds,
  };
}

export function getTextTime(timestamp, i18n) {
  if (timestamp && timestamp != "") {
    const { diffYear, diffDays } = getDiffs(timestamp);

    let textTime = "";

    if (diffYear || (diffDays && diffDays >= 2)) {
      textTime = moment(timestamp).format("DD / MM [de] YYYY");
    } else if (diffDays && diffDays == 1) {
      textTime = i18n("yesterday");
    } else {
      textTime = i18n("today");
    }

    return textTime;
  }
  return "";
}
