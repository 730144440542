import React, { memo, useCallback } from "react";

import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PrimaryButton from "../../../components/PrimaryButton";

import { setCreateSequence } from "../../../config/store/reducers/sheetsReducer";

import ShieldIcon from "../../../assets/shield-icon.svg";

function Empty() {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "app.empty" });

  const handleNewPassword = useCallback(() => {
    dispatch(
      setCreateSequence({
        hash: null,
        encrypted: null,
      })
    );
  }, []);

  const description = t("description", { returnObjects: true });

  return (
    <main className="bg-paper w-full h-[85vh] flex flex-col justify-center items-center">
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        className="my-16 w-[140px] max-[800px]:w-[120px] max-[600px]:w-[100px] max-[600px]:my-12 pointer-events-none"
        src={ShieldIcon}
      />
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="font-medium text-3xl text-center max-[800px]:text-2xl max-[600px]:text-xl"
      >
        {description[0]}
        <br />
        {description[1]}
        <b className="font-semibold"> {description[2]}</b>.
      </motion.h1>
      <PrimaryButton
        onClick={() => handleNewPassword()}
        title={t("new_password")}
        buttonStyle="mt-12 px-12"
        animationProps={{
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
        }}
      />
    </main>
  );
}

export default memo(Empty);
