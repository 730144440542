import { NavLink } from "react-router-dom";

export default function HomeFooter() {
  return (
    <footer className="flex items-center justify-end relative w-full h-[60px] self-end max-[1700px]:px-12 px-32">
      <NavLink
        target="_blank"
        to="https://sixcry.com"
        className="text-gray-300 text-xs hover:text-gray transition-all"
      >
        Safety by <span className="italic">Sixcry</span>
      </NavLink>
    </footer>
  );
}
