import React from "react";

export default function Input({
  title,
  onChangeText,
  inputStyle = "",
  ...props
}) {
  return (
    <div className={`flex flex-col ${inputStyle}`}>
      <span className="font-medium text-lg max-[600px]:text-base">{title}</span>
      <input
        className={`mt-4 border-2 rounded-2xl h-14 px-6 text-base max-[600px]:text-sm`}
        onChange={(e) => onChangeText(e.target.value)}
        {...props}
      />
    </div>
  );
}
