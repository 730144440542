import React, { useCallback, useEffect, useState } from "react";

import { httpsCallable } from "firebase/functions";

import { useSelector, useDispatch, useStore } from "react-redux";
import { motion } from "framer-motion";

import { functions } from "../constants/firebase-config";

import Input from "../components/Input";
import PrimaryButton from "../components/PrimaryButton";
import SheetWrapper, { SheetBox } from "../components/SheetWrapper";

import { getTimestamp } from "../utils/getTimestamp";

import { setCreateSequence } from "../config/store/reducers/sheetsReducer";
import { addNewPassword } from "../config/store/reducers/userReducer";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoadingIcon } from "../assets/loader.svg";
import { useToast } from "../contexts/ToastProvider";

export default function CreateSheet() {
  var clicks_count = 0;

  const dispatch = useDispatch();
  const store = useStore();
  const createSequence = useSelector((state) => state.sheets.createSequence);
  const { t } = useTranslation("translation", {
    keyPrefix: "app.create_sequence",
  });

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const { toggle } = useToast();

  useEffect(() => {
    if (
      createSequence &&
      !createSequence?.hash &&
      !createSequence?.encryptedId
    ) {
      getNewHash();
    }
  }, [createSequence]);

  const getNewHash = async () => {
    setLoading(true);

    try {
      const newHashRequest = httpsCallable(
        functions,
        "generateNewEncryptedPassword"
      );

      const results = await newHashRequest(
        createSequence?.encryptedId
          ? { lastEncryptedId: createSequence?.encryptedId }
          : null
      );
      const data = results?.data;

      dispatch(
        setCreateSequence({
          hash: data?.hash,
          encryptedId: data?.encryptedId,
        })
      );
    } catch (error) {
      alert("Generate error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewHash = useCallback(async () => {
    if (!createSequence) return;

    // ! bad practice
    let passwordsList = [...store.getState().user.data.passwords];
    const passwordData = {
      id: String(passwordsList.length + 1),
      name: name,
      hash: createSequence.encryptedId,
      timestamp: getTimestamp(),
    };

    passwordsList.push(passwordData);

    navigator.clipboard.writeText(createSequence?.hash);
    toggle({
      title: "Senha",
      description: "A senha gerada foi copiada para o seu clipboard",
    });

    setName("");
    dispatch(addNewPassword(passwordData));
    dispatch(setCreateSequence(null));
  }, [name, createSequence]);

  function handleHash() {
    clicks_count++;

    setTimeout(() => {
      clicks_count = 0;
    }, 200);

    if (clicks_count >= 2) {
      getNewHash();
    }
  }

  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === "Enter" || e.key == "Enter") {
        handleNewHash();
      }
    },
    [handleNewHash]
  );

  return (
    <SheetWrapper
      visible={!!createSequence}
      onClose={() => dispatch(setCreateSequence(null))}
    >
      <SheetBox
        title={t("title")}
        description={t("description")}
        icon={<i size="24px" className="bx bx-lock-alt bx-sm"></i>}
        bigSize={false}
      >
        <div>
          <section className="my-8 flex flex-col justify-center items-center max-[450px]:my-6">
            {!loading ? (
              <motion.strong
                whileTap={{ scale: 0.9 }}
                onClick={() => handleHash()}
                className="font-semibold text-2xl cursor-pointer select-none max-[450px]:text-xl"
              >
                {createSequence?.hash}
              </motion.strong>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0 }}
                className="mr-2 max-[800px]:mr-0 self-center"
              >
                <LoadingIcon
                  stroke="#AAB0B4"
                  className={`littleLoadingIcon  w-[40px]  max-[800px]:w[10px]`}
                />
              </motion.div>
            )}
            <motion.span
              whileHover={{ opacity: 0.5 }}
              className="text-sm mt-2 text-gray text-center px-20 max-[450px]:text-xs max-[450px]:mt-1"
            >
              {t("two_clicks")}
            </motion.span>
          </section>
          <section className="px-8 max-[450px]:px-6">
            <Input
              title={t("nick_name.title")}
              placeholder={t("nick_name.placeholder")}
              value={name}
              onChangeText={(value) => setName(value)}
              onKeyDown={(e) => handleKeyDown(e)}
              autoFocus
              maxLength={20}
            />
            <div className="flex items-center justify-between mt-4 mb-6">
              <PrimaryButton
                title={t("create")}
                buttonStyle="w-full mr-2"
                onClick={() => handleNewHash()}
                disabled={!name || !createSequence?.hash}
              />
              <PrimaryButton
                title={t("close")}
                buttonStyle="w-full"
                onClick={() => dispatch(setCreateSequence(null))}
                grayStyle
              />
            </div>
          </section>
        </div>
      </SheetBox>
    </SheetWrapper>
  );
}
