import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";

import { languages } from "../../config/i18n";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [showsLanguages, setShowsLanguages] = useState(false);

  const linksLists = [
    { to: "/legal", children: t("legal") },
    { to: "/legal/terms", children: t("terms_of_service") },
    { to: "/legal/policy", children: t("data_policy") },
  ];

  return (
    <footer className="relative pb-4 flex justify-center items-center flex-col px-10 max-[800px]:px-8">
      <div className="h-1 w-[50vw] bg-gray-200 rounded-xl  max-[1100px]:w-[80vw]" />
      <div className="flex items-center justify-between w-[50vw] mt-4 max-[1100px]:flex-col max-[1100px]:justify-center">
        <span className="text-gray text-sm font-medium">CloudSky @2024</span>
        <span className="text-gray text-sm max-[1100px]:hidden">●</span>
        <ul className="items-center justify-center flex max-[1100px]:flex-col max-[1100px]:mt-4">
          {linksLists.map((item, index) => (
            <li key={index}>
              <a
                className={cn("text-gray text-sm text-center font-regular", {
                  "ml-20 max-[1100px]:ml-0": index > 0,
                })}
                href={`https://cloudsky.app/${item.to}`}
              >
                {item.children}
              </a>
            </li>
          ))}
          <li className="text-center">
            <a
              href="mailto:cloudsky.client@gmail.com"
              className="text-gray ml-20 text-sm text-center max-[1100px]:ml-0 font-regular"
            >
              {t("contact")}
            </a>
          </li>
        </ul>
      </div>
      <AnimatePresence>
        {showsLanguages && (
          <motion.ul
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 20 }}
            className="flex flex-col items-center absolute bottom-28 bg-white rounded-xl shadow-md w-[120px] py-4"
          >
            {languages
              .filter((lng) => lng != i18n.language)
              .map((lng, index) => (
                <li className="my-2" key={index}>
                  <button
                    className="hover:text-black text-gray text-base max-[1100px]:text-sm"
                    onClick={() => {
                      i18n.changeLanguage(lng);
                      setShowsLanguages(false);
                    }}
                  >
                    {lng.toUpperCase()}
                  </button>
                </li>
              ))}
          </motion.ul>
        )}
      </AnimatePresence>
      <button
        className="flex items-center text-gray text-sm mt-4"
        onClick={() => setShowsLanguages(!showsLanguages)}
      >
        {i18n.language.toUpperCase()}{" "}
        <div
          className={cn("flex items-center justify-center ml-2", {
            "rotate-180": !!showsLanguages,
          })}
        >
          <i color="#AAB0B4" className="bx bx-chevron-down bx-xs"></i>
        </div>
      </button>
    </footer>
  );
}
