import React from "react";

import { motion } from "framer-motion";

import { ReactComponent as LoaderIcon } from "../assets/loader.svg";

export default function PrimaryButton({
  title,
  titleStyle = "",
  grayStyle,
  onClick,
  buttonStyle,
  disabled,
  animationProps,
  loading,
  loadingColor = "#ffffff",
}) {
  return (
    <motion.button
      whileHover={{ opacity: disabled ? 0.6 : 0.8 }}
      whileTap={{ scale: disabled ? 1 : 0.8 }}
      onClick={onClick}
      className={`transition-all h-14 px-8 rounded-2xl text-base ${
        grayStyle ? "text-black bg-gray-200" : "text-white bg-primary"
      } ${disabled && "opacity-60"} ${
        loading && "flex justify-center items-center"
      } max-[600px]:text-sm max-[600px]:h-13 ${buttonStyle || ""}`}
      disabled={disabled || loading}
      {...animationProps}
    >
      {!loading ? (
        <span className={titleStyle}>{title}</span>
      ) : (
        <LoaderIcon stroke={loadingColor} className="littleLoadingIcon" />
      )}
    </motion.button>
  );
}
