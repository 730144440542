import React, { useCallback, useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

import SheetWrapper, { SheetBox } from "../components/SheetWrapper";
import PricingDetail from "../components/PricingDetail";
import PricingButton from "../components/PricingButton";

import { db, auth, functions } from "../constants/firebase-config";
import { setCheckoutSequence } from "../config/store/reducers/sheetsReducer";

import { ReactComponent as SkyBigIcon } from "../assets/sky_big.svg";
import { httpsCallable } from "firebase/functions";
import i18n from "../config/i18n";

var sessionSnap = null;

export default function CheckoutSheet() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const checkoutSequence = useSelector(
    (state) => state.sheets.checkoutSequence
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "app.checkout_sequence",
  });

  const [loadingCurrency, setLoadingCurrency] = useState(true);
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    if (!currency && checkoutSequence) {
      getCurrency();
    }
  }, [currency, checkoutSequence]);

  useEffect(() => {
    if (!loading && sessionSnap) {
      sessionSnap();
      sessionSnap = null;
    }
  }, [loading]);

  async function getCurrency() {
    try {
      const getCurrency = httpsCallable(functions, "getCurrency");
      const dynamicCurrency = await getCurrency();
      setCurrency(dynamicCurrency.data);
    } catch (error) {
      alert("Error to show");
    } finally {
      setLoadingCurrency(false);
    }
  }

  const handleNewCheckoutSession = useCallback(async () => {
    try {
      setLoading(true);
      const uid = auth.currentUser.uid;
      const checkoutSessionRef = collection(
        db,
        `users/${uid}/checkout_sessions/`
      );

      const newSessionRef = await addDoc(checkoutSessionRef, {
        price: process.env.REACT_APP_PRICE_ID,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        collect_shipping_address: false,
      });

      sessionSnap = onSnapshot(newSessionRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
          setLoading(false);
        }
        if (url) {
          window.location.assign(url);
          setLoading(false);
        }
      });
    } catch (error) {
      alert(`An error occured: ${error}`);
      setLoading(false);
    }
  }, [sessionSnap]);

  const paymentsFooter = t("payments_footer", {
    returnObjects: true,
  });

  return (
    <SheetWrapper
      visible={!!checkoutSequence}
      onClose={() => dispatch(setCheckoutSequence(null))}
    >
      <SheetBox bigSize loading={loadingCurrency}>
        <section className="py-4 flex flex-col justify-center items-center max-[450px]:py-4">
          <motion.div
            initial={{ opacity: 0, rotate: -45 }}
            animate={{ opacity: 1, rotate: 0 }}
            transition={{
              delay: 0.2,
              duration: 0.8,
              type: "spring",
              bounce: 0.6,
            }}
            whileHover={{
              rotate: 180,
              transition: { delay: 0 },
            }}
          >
            <SkyBigIcon className="w-[110px] max-[600px]:w-[100px]" />
          </motion.div>
          <strong className="mt-2 text-2xl font-semibold max-[450px]:mt-2 max-[400px]:text-xl">
            {t("title")}
          </strong>
        </section>
        <section className="flex flex-row items-center justify-center mb-4">
          <motion.div
            initial={{ scale: 1.0 }}
            whileHover={{ scale: 1.05 }}
            transition={{
              bounce: 10,
            }}
            className="flex flex-col items-center cursor-pointer"
          >
            <span className="text-black text-base font-medium">Individual</span>
            <div className="mt-1 w-6 h-1 bg-primary rounded-full" />
          </motion.div>
          <div className="ml-4 cursor-default">
            <span className="text-gray text-sm">
              {t("organization")} <span className="text-xs">({t("soon")})</span>
            </span>
          </div>
        </section>
        <div className="h-[1px] bg-gray-200" />
        <section className="flex flex-col justify-center items-center py-4 max-[450px]:py-4">
          <div className="flex flex-col my-2">
            <PricingDetail
              title={t("plan_details.no_limite.title")}
              description={t("plan_details.no_limite.description")}
            />
            <PricingDetail
              title={t("plan_details.encryption.title")}
              description={t("plan_details.encryption.description")}
            />
            <PricingDetail
              title={t("plan_details.updates.title")}
              description={t("plan_details.updates.description")}
            />
          </div>
          <div className="flex flex-col mt-2 justify-center items-center max-[600px]:mt-6 max-[450px]:mt-2">
            <strong className="text-lg font-medium max-[600px]:text-lg max-[450px]:text-base">
              {currency &&
                new Intl.NumberFormat(currency.currency, {
                  style: "currency",
                  currency: currency.currency,
                })
                  .format(currency.unit_amount / 100)
                  .replace("US", "")}{" "}
              <span className="ml-2 text-gray-400">/ {t("month")}</span>
            </strong>
            <PricingButton
              title={t("sign")}
              contentStyle="mt-4"
              onClick={() => handleNewCheckoutSession()}
              loading={loading}
            />
            <span className="text-[10px] mt-4 text-gray-400 text-center max-w-[400px]">
              {paymentsFooter[0]}{" "}
              <a
                href={`https://cloudsky.app/${i18n.language}/legal/terms`}
                className="underline"
              >
                {paymentsFooter[1]}
              </a>{" "}
              {paymentsFooter[2]}{" "}
              <a
                href={`https://cloudsky.app/${i18n.language}/legal/policy`}
                className="underline"
              >
                {paymentsFooter[3]}
              </a>{" "}
              {paymentsFooter[4]}
            </span>
          </div>
        </section>
      </SheetBox>
    </SheetWrapper>
  );
}
