import React from "react";

import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as LoadingIcon } from "../assets/loader.svg";

export default function SheetWrapper({ children, visible, onClose, boxMode }) {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`absolute inset-0 w-full h-[100vh] flex justify-center items-center`}
        >
          <motion.div
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className={`absolute inset-0 w-full h-[100vh] backdrop-blur-sm ${
              boxMode && "shadow-2xl self-center rounded-xl"
            }`}
            style={{
              backgroundColor: !boxMode ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 0.8)",
            }}
          />
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function SheetBox({
  children,
  title,
  icon,
  description,
  descriptionComponent,
  bigSize = false,
  loading,
}) {
  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{
        duration: 0.5,
        type: "spring",
      }}
      className={`bg-white shadow-lg ${
        !bigSize ? "w-[400px]" : "w-[550px]"
      } rounded-2xl z-10 max-[450px]:w-[350px] max-[350px]:w-[300px]`}
    >
      {!loading ? (
        <>
          {title && (
            <section>
              <div className="flex flex-row items-start p-8 max-[450px]:p-6">
                {icon}
                <div className="ml-4">
                  <span className="text-lg font-medium max-[450px]:text-base">{title}</span>
                  {description && !descriptionComponent && (
                    <p className="mt-1 text-base text-gray max-[450px]:text-sm">{description}</p>
                  )}
                  {!description && descriptionComponent}
                </div>
              </div>
              <div className="h-[1px] bg-gray-200" />
            </section>
          )}
          {children}
        </>
      ) : (
        <div className="flex justify-center items-center py-8">
          <LoadingIcon
            stroke="#AAB0B4"
            className={`littleLoadingIcon  w-[28px]  max-[800px]:w[10px]`}
          />
        </div>
      )}
    </motion.div>
  );
}
