import React, { memo, useCallback } from "react";

import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  setCheckoutSequence,
  setCreateSequence,
  setDeleteSequence,
} from "../../../config/store/reducers/sheetsReducer";

import PasswordItem from "./PasswordItem";

import { ReactComponent as Sky } from "../../../assets/sky.svg";

function PasswordList({ data, glassesMode, subscription }) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "app.password_list",
  });

  const handleNewPassword = useCallback(() => {
    if (!subscription && data.length >= 3) {
      return;
    }

    dispatch(
      setCreateSequence({
        hash: null,
        encryptedId: null,
      })
    );
  }, [subscription, data]);

  const handleDeletePassword = useCallback((item) => {
    dispatch(setDeleteSequence(item));
  }, []);

  let word = data.length > 1 ? t("passwords") : t("password");
  let maximum = !subscription ? " / 3" : null;
  let count = data.length + maximum + ` ${word}`;

  return (
    <div className="w-full min-h-[80vh] px-10 py-4 bg-center max-[500px]:px-4 bg-transparent">
      <section className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="flex items-center">
            <h1 className="font-semibold text-3xl max-[600px]:text-2xl">{t("title")}</h1>
            {((!subscription && data.length < 3) || subscription) && (
              <motion.button
                whileTap={{ scale: 0.8 }}
                onClick={() => handleNewPassword()}
                className="flex justify-center items-center w-12 h-12 rounded-xl ml-10 max-[450px]:w-10 max-[450px]:h-10 max-[450px]:rounded-lg max-[450px]:ml-6"
                style={{
                  backgroundImage: "linear-gradient(45deg, #D4CEFF60, #FF62FF40, #ffffff)",
                }}
              >
                <i className="bx bx-plus text-[20px]"></i>
              </motion.button>
            )}
          </div>
          <p className="font-medium text-sm mt-1 text-gray max-[600px]:text-xs max-[600px]:mt-0">
            ({count} {t("created")}
            {i18n.language != "en" && data.length > 1 && "s"})
          </p>
        </div>
        {!subscription && (
          <motion.button
            className="max-[800px]:hidden"
            whileTap={{ scale: 0.9 }}
            onClick={() => dispatch(setCheckoutSequence({ visible: true }))}
          >
            <div
              className="h-12 bg-primary flex justify-center items-center w-[200px] rounded-xl"
              style={{
                backgroundImage: "linear-gradient(45deg, #758BFD 35%, #FFE577, #FF62FF40)",
              }}
            >
              <div
                className="h-[93.5%] w-[98%] bg-white flex flex-row justify-center items-center"
                style={{
                  borderRadius: 10,
                }}
              >
                <Sky width={20} />
                <span className="font-medium text-base text-black ml-4">
                  {i18n.t("app.header.plan.sky_plan_name")}
                </span>
              </div>
            </div>
          </motion.button>
        )}
      </section>

      <section className={"pt-8 w-full flex flex-wrap gap-3 max-[1120px]:flex-col"}>
        {data.map((item, index) => (
          <PasswordItem
            index={index}
            length={data.length}
            key={item.id}
            data={item}
            handleDelete={handleDeletePassword}
            glassesMode={glassesMode}
          />
        ))}
      </section>
    </div>
  );
}

export default memo(PasswordList);
