import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Input from "../components/Input";
import PrimaryButton from "../components/PrimaryButton";
import SheetWrapper, { SheetBox } from "../components/SheetWrapper";

import { setDeleteSequence } from "../config/store/reducers/sheetsReducer";
import { deletePassword } from "../config/store/reducers/userReducer";

export default function CreateSheet() {
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const deleteSequence = useSelector((state) => state.sheets.deleteSequence);
  const { t } = useTranslation("translation", {
    keyPrefix: "app.delete_sequence",
  });

  const handleDeleteConfirm = () => {
    setName("");
    dispatch(deletePassword(deleteSequence.id));
    dispatch(setDeleteSequence(null));
  };

  const description = t("description", {
    returnObjects: true,
  });

  return (
    <SheetWrapper
      visible={!!deleteSequence}
      onClose={() => dispatch(setDeleteSequence(null))}
    >
      <SheetBox
        title={t("title")}
        descriptionComponent={
          <p className="mt-1 text-base text-gray max-[450px]:text-sm">
            {description[0]}{" "}
            <span className="font-semibold text-black">
              {deleteSequence?.name}
            </span>{" "}
            {description[1]}{" "}
          </p>
        }
        icon={<i size="24px" className="bx bx-trash-alt bx-sm"></i>}
      >
        <div>
          <section className="px-8">
            <Input
              title={t("nick_name.title")}
              placeholder={t("nick_name.placeholder")}
              value={name}
              onChangeText={(value) => setName(value)}
              inputStyle="mt-4"
              autoFocus
            />
            <div className="flex items-center justify-between mt-4 mb-6">
              <PrimaryButton
                title={t("delete")}
                buttonStyle="w-full mr-2 bg-red"
                disabled={name != deleteSequence?.name}
                onClick={() => handleDeleteConfirm()}
              />
              <PrimaryButton
                title={t("close")}
                buttonStyle="w-full"
                onClick={() => dispatch(setDeleteSequence(null))}
                grayStyle
              />
            </div>
          </section>
        </div>
      </SheetBox>
    </SheetWrapper>
  );
}
