import React from "react";

import { motion } from "framer-motion";

import LogoIcon from "../assets/logo-icon.svg";

export default function MainLoader() {
  return (
    <div className="w-full h-[90vh] bg-white flex justify-center items-center flex-col">
      <motion.img
        className="w-[160px]"
        drag
        dragConstraints={{ bottom: 0, left: 0, right: 0, top: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        src={LogoIcon}
      />
    </div>
  );
}
