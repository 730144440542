import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";

export default function Header() {
  return (
    <header className="flex items-center justify-center mt-8">
      <Logo
        onClick={() => window.location.assign("https://cloudsky.app/")}
        width={130}
        className="cursor-default"
      />
    </header>
  );
}
