import React, { useCallback, useEffect, useState } from "react";

import { httpsCallable } from "firebase/functions";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";

import { saveRevealedPassword } from "../../../config/store/reducers/userReducer";
import { getTextTime } from "../../../utils/getTimestamp";
import { functions } from "../../../constants/firebase-config";

import Copied from "../../../assets/lotties/copied.json";
import LoadingCriptography from "../../../assets/lotties/loading_criptography.json";

const breakPoints = `max-[1610px]:w-[280px] max-[1510px]:w-[260px] max-[1410px]:w-[240px]  max-[1310px]:w-[220px] max-[1210px]:w-[200px] max-[1120px]:w-[80vw] max-[600px]:w-[86vw] max-[400px]:w-[91vw]`;

export default function PasswordItem({ data, index, length, handleDelete, glassesMode }) {
  const { t } = useTranslation("translation", {
    keyPrefix: "app.password_list",
  });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [hashText, setHashText] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hashCopied, setHashCopied] = useState(false);

  const timeText = getTextTime(data?.timestamp, t);

  useEffect(() => {
    if (!hidePassword) {
      getSpecificHash();
    }
  }, [hidePassword, data]);

  const getSpecificHash = useCallback(async () => {
    setLoading(true);
    try {
      if (!data.revealedHash) {
        const revealHash = httpsCallable(functions, "revealEncryptedPassword");

        const hashRevealed = await revealHash({
          encryptedId: data.hash,
        });

        if (!hashRevealed.data) {
          setHidePassword(true);
          return;
        }

        setHashText(hashRevealed.data);
        dispatch(
          saveRevealedPassword({
            encryptedId: data.hash,
            revealedHash: hashRevealed.data,
          })
        );
        return hashRevealed.data;
      }

      setHashText(data.revealedHash);
      return data.revealedHash;
    } catch (error) {
      alert("Get Hash error: ", error);
    } finally {
      setLoading(false);
    }
  }, [data]);

  const copySpecificHash = useCallback(async () => {
    const hashDecrypted = await getSpecificHash();
    navigator.clipboard.writeText(hashDecrypted);
    setHashCopied(true);
  }, []);

  const onEventCopyHash = useCallback(
    (event) => {
      if (event == "complete" && hashCopied) {
        setTimeout(() => {
          setHashCopied(false);
        }, 1000);
      }
    },
    [hashCopied]
  );

  const shortName =
    data.name.length >= 10 && window.innerWidth <= 1400
      ? data.name.slice(0, 10) + "..."
      : data.name;

  return (
    <motion.div
      key={index}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: index / (length * length - 10) }}
      className="w-[20%] h-44 max-[600px]:h-36"
    >
      <motion.div
        whileHover={{ scale: 0.99, backgroundColor: "#ffffff" }}
        transition={{ duration: 0.3 }}
        className={`cursor-default backdrop-blur w-[300px] ${breakPoints} h-full rounded-3xl p-6 flex flex-col justify-between hover:border-[1px] ${
          !glassesMode && "border-[1.5px]"
        } border-gray-200`}
        style={{ backgroundColor: "rgba(255,255,255,0.4)" }}
      >
        <div>
          <section className="flex flex-row justify-between items-start">
            <p className="text-lg break-all max-[600px]:text-base">{shortName}</p>
            <div className="flex flex-row items-center pl-2">
              <motion.div
                onClick={() => setHidePassword(!hidePassword)}
                whileTap={{ scale: 0.8 }}
                className="cursor-pointer flex justify-center items-center max-[1400px]:w-5 mx-1"
              >
                {hidePassword ? (
                  <i className="bx bx-show-alt text-[24px]"></i>
                ) : (
                  <i className="bx bx-hide text-[24px]"></i>
                )}
              </motion.div>
              <motion.div
                onClick={() => copySpecificHash()}
                whileTap={{ scale: 0.8 }}
                className="cursor-pointer mx-2 flex items-center justify-center max-[1400px]:w-5 max-[1400px]:mx-2"
              >
                {!hashCopied ? (
                  <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="flex justify-center items-center"
                  >
                    <i className="bx bx-copy-alt text-[24px]"></i>
                  </motion.div>
                ) : (
                  <Player
                    autoplay
                    src={Copied}
                    onEvent={onEventCopyHash}
                    keepLastFrame
                    className="self-center"
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                )}
              </motion.div>
              <motion.div
                onClick={() => handleDelete(data)}
                whileTap={{ scale: 0.8 }}
                className="cursor-pointer mx-1 flex items-center justify-center max-[1300px]:w-5 max-[1300px]:mx-1 "
              >
                <i className="bx bx-trash-alt text-[24px]"></i>
              </motion.div>
            </div>
          </section>
          {!loading ? (
            <p
              className={`my-4 text-xl font-medium ${
                hidePassword && "tracking-wide text-2xl "
              }  max-[1300px]:text-base`}
            >
              {!hidePassword && hashText ? hashText : "●●●●●●●●●●"}
            </p>
          ) : (
            <Player
              loop
              autoplay
              src={LoadingCriptography}
              speed={3}
              style={{
                height: "50px",
                marginTop: 10,
              }}
            />
          )}
        </div>
        <p className="text-xs text-gray">{timeText}</p>
      </motion.div>
    </motion.div>
  );
}
