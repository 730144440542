import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { animate, AnimatePresence, motion } from "framer-motion";

const ToastContext = createContext({});

function ToastProvider({ children }) {
  const [data, setData] = useState(null);
  const previousData = useRef(null);

  const toggle = useCallback(
    (toastData) => {
      if (!toastData && data) {
        setData(null);
        return;
      }

      setData(toastData);
    },
    [data]
  );

  useEffect(() => {
    if (!previousData.current && data) {
      setTimeout(() => {
        setData(null);
      }, 4000);
    }

    previousData.current = data;
  }, [previousData, data]);
  return (
    <ToastContext.Provider value={{ toggle }}>
      {children}
      <AnimatePresence>
        {data && (
          <motion.div
            open
            className="fixed bottom-6 p-4 right-6 bg-white text-black shadow-lg max-w-xs sm:max-w-sm lg:max-w-md min-h-[100px] rounded-2xl border-bg-primary border-1 z-50  "
            initial={{
              y: -30,
              opacity: 0,
              scale: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              scale: 1,
            }}
            exit={{
              scale: 0.8,
              opacity: 0,
            }}
          >
            <p className="text-black font-medium">{data.title}</p>
            {data.description && (
              <span className="text-gray mt-1">{data.description}</span>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);

export default ToastProvider;
